import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import Seo from '../components/SEO/seo';
import Layout from '../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Cookies",
  "path": "/cookies"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Cookie Statement`}</h1>
    <p>{`Our website (“Site”) uses cookies to distinguish you from other users on our site. This helps us to optimize the user experience of your visit. By using our website you agree with our cookies.`}</p>
    <p>{`A cookie is a small textfile that we store on your browser, computer or phone. Cookies remember what you do on our website. It could be that some functionality on our site requires the use of cookies. The reports that we create with it are anonymous, we can't trace it to a person.`}</p>
    <h2>{`1. Types of cookies`}</h2>
    <p>{`We use the following cookies:`}</p>
    <p><strong parentName="p">{`Functional cookies`}</strong>{`
The cookies are used to let our site function optimal, by remembering forms you have filled in. This helps you too. Or to read your browser preferences to let our site perform optimal.`}</p>
    <p><strong parentName="p">{`Analytical cookies`}</strong>{`
With these cookies we can measure our site usage, to determine what parts of our website are the most relevant for our users. We use the software from Google Analytics. We keep track of how many visitors we have, what moments of the day and what a visitor is looking at.`}</p>
    <p>{`Some third parties like YouTube or advertising platforms can also use cookies, but we don't have control of these. Probably these are functional and analytical cookies. It is possible to block all cookies in your browser settings, it could be that our site doesn't work optimal then.`}</p>
    <h2>{`2. Cookies in our app`}</h2>
    <p>{`In our app we also keep track of the app usage. These are anonymous statistics that could help us gain insight. You can't set your preferences for cookie usage in the app. The statistics can never be traced back to people because we respect your privacy. Read our Privacy Statement to see how we handle this type of information.`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      